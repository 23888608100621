<template>
  <div class="contact">

    <contact-form />

  </div>
</template>

<script>
import contactForm from '../components/contactForm.vue'

export default {
  name: 'Contact',

  components: {
    contactForm
  },

  data() {
    return {
      

    }
  },



  computed: {
    
  },

}
</script>







<style scoped>
  .snippets {
    padding-top: 1rem;
  }

  .logoCol {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logoRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 44rem;
    margin: 3rem;
  }

  .logoContainer {
    display: flex;
    justify-content: center;
  }

  .logoOption {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17rem;
  }

  .label {
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    color: #555;
    font-size: 1.25rem;
    font-weight: bold;
    box-shadow: 0 0 2.5px .75px #aaa;
  }
</style>