<template>
  <div class="formContainer">

    <form>

      <div class="inputRow">
        <label 
          for="contactName" 
          class="inputLabel"
        >
          name
        </label>

        <input 
          type="text"
          id="contactName" 
          pattern=""
          v-model="name"
        >
      </div>

      <div class="inputRow">
        <label 
          for="contactEmail"
        >
          email
        </label>

        <input 
          type="text"
          id="contactEmail"
        >
      </div>

      <div class="inputRow">
        <label
          for="contactMsg"
        >
          message
        </label>

        <textarea 
          name="contactMsg" 
          id="contactMsg" 
          cols="30" 
          rows="10"
        >
        </textarea>
      </div>

    </form>

  </div>
</template>





<script>
export default {
  data() {
    return {
      name: '',
      company: '',
      email: '',
      msg: ''
    }
  },
}
</script>